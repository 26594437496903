<template>
  <el-main style="padding: 0;">
    <!-- banner -->
    <el-skeleton :loading="loading" animated>
      <template slot="template">
        <el-skeleton-item
            variant="image"
            style="height: 400px;"/>
      </template>
      <template>
        <swiper v-if="options.banners && options.banners.list.length > 0" :options="swiperOption" class="banner">
          <swiper-slide :key="`banner`+ i" v-for="(banner, i) in options.banners.list">
            <a :href="banner.url">
              <img :title="banner[lang + 'title']" :src="banner.image">
              <div class="banner_shadow banner_shadow--20"></div>
              <div class="banner_text" v-if="banner[lang + 'title'] || banner[lang + 'desc']">
                <div class="ten_main">
                  <h2>{{banner[lang + 'title']}}</h2>
                  <h5>{{banner[lang + 'desc']}}</h5>
                </div>
              </div>
            </a>
          </swiper-slide>
          <!-- Add Pagination -->
          <div class="swiper-pagination" slot="pagination"></div>
          <!-- Add Arrows -->
          <!--<div class="banner-swiper-button swiper-button-prev" slot="button-prev"></div>-->
          <!--<div class="banner-swiper-button swiper-button-next" slot="button-next"></div>-->
        </swiper>
      </template>
    </el-skeleton>

    <!-- 解决方案 -->
    <div class="container" v-if="options.solution">
      <div class="wrap_content">
        <div class="wrap_content_title">
          <h3>{{options.solution[lang + 'title']}}</h3>
          <div class="item-inner">
            <div class="item-title">{{options.solution[lang + 'desc']}}</div>
            <div class="item-after">
              <router-link :to="{ path: '/solution' }">{{$t('page.home.more')}}</router-link>
            </div>
          </div>
        </div>

        <div class="wrap_content_item">
          <el-row :gutter="20" type="flex" justify="start" class="flex-wrap">
            <el-col v-for="(solution, i) in options.solution.list"
                    :key="`solution` + i"
                    :xs="i == 0 ? 24 :12"
                    :sm="i == 0 ? 24 :12"
                    :md="i == 0 ? 16 :8"
                    :lg="i == 0 ? 16 :8"
                    :xl="i == 0 ? 16 :8">
              <div class="wrap_content_item_box" @click="goto(solution.url)">
                <img :src="solution.image"/>
                <div class="wrap_content_item_box_content_mask"></div>
                <div class="wrap_content_item_box_content_title">{{solution[lang + 'title']}}</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <!-- 产品展示 -->
    <div class="bg-grey" v-if="options.product">
      <div class="container">
        <div class="wrap_content">
          <div class="wrap_content_title pt-20">
            <h3>{{options.product[lang + 'title']}}</h3>
            <div class="item-inner">
              <div class="item-title">{{options.product[lang + 'desc']}}</div>
              <div class="item-after">
                <router-link :to="{ path: '/productCenter' }">{{$t('page.home.more')}}</router-link>
              </div>
            </div>
          </div>

          <div class="wrap_content_item">
            <el-row :gutter="20" type="flex" justify="start" class="flex-wrap">
              <el-col v-for="(product, i) in options.product.list"
                      :key="`product` + i"
                      :xs="12"
                      :sm="12"
                      :md="12"
                      :lg="8"
                      :xl="8">
                <div class="wrap_content_item_box"  @click="goto(product.url)" style="display: block; margin-bottom: 10px;">
                    <div class="wrap_content_item_box_img">
                      <img :src="product.image"/>
                    </div>
                    <div class="wrap_content_item_box_desc">
                      <p>{{product[lang + 'title']}}</p>
                    </div>
                </div>
              </el-col>

            </el-row>
          </div>
        </div>
      </div>
    </div>

    <!-- 最新动态 -->
    <div class="container" v-if="options.dynamics">
      <div class="wrap_content">
        <div class="wrap_content_title">
          <h3>{{options.dynamics[lang + 'title']}}</h3>
          <div class="item-inner">
            <div class="item-title">{{options.dynamics[lang + 'desc']}}</div>
            <div class="item-after">
              <router-link :to="{ path: '/media' }">{{$t('page.home.more')}}</router-link>
            </div>
          </div>
        </div>

        <div class="wrap_content_item">
          <el-row :gutter="20" type="flex" justify="start" class="flex-wrap">

            <template
                v-for="(dynamics, i) in options.dynamics.list">
              <el-col
                  v-if="dynamics.type == 'card'"
                  :xs="dynamics.type == 'text' ? 24 : 12"
                  :sm="dynamics.type == 'text' ? 24 : 12"
                  :md="dynamics.type == 'text' ? 24 : 8"
                  :lg="dynamics.type == 'text' ? 24 : 8"
                  :xl="dynamics.type == 'text' ? 24 : 8">
                <!--<div class="wrap_content_item_box" @click="goto(dynamics.url)" v-if="dynamics.type == 'image'">
                    <img :src="dynamics.image"/>
                    <div class="wrap_content_item_box_content_mask"></div>
                    <div class="wrap_content_item_box_content_title">{{dynamics[lang + 'title']}}</div>
                </div>-->

                <div @click="goto(dynamics.url)" class="wrap_content_item_box" style="display: block;">
                  <el-image style="width: 100%; height: 180px;" fit="cover" :src="dynamics.image"/>
                  <div class="wrap_content_item_box_card" @click="goto(dynamics.url)">
                    <h3 class="title">{{dynamics[lang + 'title']}}</h3>
                    <p class="desc">{{dynamics[lang + 'desc']}}</p>
                  </div>

                  <div class="entry-content">
                    <span>{{dynamics.create_time}}</span>
                  </div>
                </div>
              </el-col>

              <!--<el-row class="dynamics-carousel" type="flex" justify="start" v-if="dynamics.type == 'text'" :gutter="20" >-->
                <el-col
                    v-if="dynamics.type == 'text'"
                    :xs="24"
                    :sm="24"
                    :md="16"
                    :lg="16"
                    :xl="16">
                  <el-carousel class="dynamics-carousel" ref="carousel" @change="changeImage" :autoplay="false">
                    <el-carousel-item v-for="(text, index) in dynamics.list" :key="`carousel`+ index" :name="`carousel` + index">
                      <el-image @click="goto(text.url)" class="dynamics-img" :src="text.image"></el-image>
                    </el-carousel-item>
                  </el-carousel>
                </el-col>

                <!--<el-col
                    :xs="24"
                    :sm="24"
                    :md="8"
                    :lg="8"
                    :xl="8">
                  <ul class="dynamics-text">
                    <li v-for="(text, index) in dynamics.list" @click="goto(text.url)" :key="`dynamicsText` + index">
                        <h3 :class="{active: active == index}">{{text[lang + 'title']}}</h3>
                      &lt;!&ndash;<p class="font-rem-8 mt20">{{text[lang + 'desc']}}</p>&ndash;&gt;
                    </li>
                  </ul>
                </el-col>-->
              <!--</el-row>-->
            </template>
          </el-row>
        </div>
      </div>
    </div>

    <!-- 活动图集 -->
    <div class="wrap_content" v-if="options.activityImages">
      <div class="wrap_content_title container">
        <h3>{{options.activityImages[lang + 'title']}}</h3>
        <div class="item-inner">
          <div class="item-title">{{options.activityImages[lang + 'desc']}}</div>
          <div class="item-after">
            <router-link :to="{ path: '/media', query: {type: 'activityImages'} }">{{$t('page.home.more')}}</router-link>
          </div>
        </div>
      </div>

      <div class="wrap_content_item">
        <el-row :gutter="20" class="margin-0">
          <el-col v-for="(activity, i) in options.activityImages.list"
                  :key="`activityImages` + i"
                  :xs="12"
                  :sm="12"
                  :md="12"
                  :lg="6"
                  :xl="6"
                  @click="goto(activity.url)">
            <div class="wrap_content_item_box" @click="goto(activity.url)">
              <!--<img :src="activity.image"/>-->
              <el-image style="width: 100%; height: 180px;" fit="cover" :src="activity.image"/>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>


    <!-- 推荐 -->
    <!--<el-tabs v-model="project" @tab-click="projectClick" class="project">
        <el-tab-pane label="推荐" name="reco">
          <el-row>
            <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
              <el-card shadow="hover">
                <div class="project-item">
                  <img src="@/assets/images/project/1.png">
                  <p>好吃的汉堡</p>
                  <div class="project-item-tools">
                    <i class="el-icon-chat-dot-round"></i> 99
                    <i class="el-icon-star-off"></i> 66
                    <i class="el-icon-alarm-clock"></i> 06-26
                  </div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="最新" name="new">配置管理</el-tab-pane>
        <el-tab-pane label="精选" name="third">角色管理</el-tab-pane>
        <el-tab-pane label="定时任务补偿" name="fourth">定时任务补偿</el-tab-pane>
      </el-tabs>-->

    <!-- item -->
    <!--<div class="main-item">
        <div class="main-item-title">
          <h3>需求合作</h3>
          <p class="main-item-title-desc">为您量身打造Wordpress站点、微信小程序、微信公众号、APP开发以及相关功能实现</p>
        </div>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8" class="main-box">
            <el-card class="box-card" shadow="hover">
              <div slot="header">
                <span class="main-item-content-title">全新设计</span>
                <div class="main-item-content-icon">
                  <i class="el-icon-edit"></i>
                </div>
              </div>
              <div class="main-item-content">
                可根据客户需求量身打造UI设计风格，或根据客户需求将UI转化为现实
              </div>
            </el-card>
          </el-col>

          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8" class="main-box">
            <el-card class="box-card" shadow="hover">
              <div slot="header">
                <span class="main-item-content-title">多平台解决方案</span>
                <div class="main-item-content-icon">
                  <i class="el-icon-finished"></i>
                </div>
              </div>
              <div class="main-item-content">
                PC、手机、APP、H5、微信等等都能以最佳的方式展示给您的客户
              </div>
            </el-card>
          </el-col>

          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8" class="main-box">
            <el-card class="box-card" shadow="hover">
              <div slot="header">
                <span class="main-item-content-title">定制服务</span>
                <div class="main-item-content-icon">
                  <i class="el-icon-edit-outline"></i>
                </div>
              </div>
              <div class="main-item-content">
                您的需求即为我的工作，根据需求超乎想象的完成您的项目
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>-->

    <!-- 常见问题 -->
    <!--<div class="main-item">
        <div class="main-item-title">
          <h3>常见问题</h3>
          <p class="main-item-title-desc">我们拥有一套完整的付款、退款体系，竭尽全力为满足客户需求</p>
        </div>
        <el-row>
          <el-col :span="24">
            <el-collapse v-model="activeCollapse" accordion>
              <el-collapse-item name="1" title="关于付款">
                <div class="indent">在线商城可使用微信或支付宝在线支付购买；定制服务在确定需求后付款50%，剩余款项在完成需求后支付。</div>
              </el-collapse-item>
              <el-collapse-item title="关于退款">
                <div class="indent">因代码是可复制商品，在线商城购买成功后不支持退款，但我会尽力为你解决难题；在未交付定制需求，可随时退款，交付源代码后，不支持退款</div>
              </el-collapse-item>
              <el-collapse-item title="隐私政策">
                <div class="indent">无论您是本站用户，或定制客户，我们都将完全保护您的个人隐私（包括不限于：邮箱账户、手机号码、姓名、地址）</div>
              </el-collapse-item>
            </el-collapse>
          </el-col>
        </el-row>
      </div>-->

    <!-- <transition
            appear
            appear-class="custom-appear"
            appear-to-class="custom-appear-to"
            appear-active-class="custom-appear-active"
    >
        <p>appear</p>
    </transition> -->

    <el-popover
        placement="left"
        trigger="click"
        width="160"
        style="height: 160px;">
      <div class="wxCode">
        <h3>扫码联系专属客服</h3>
        <p>一对一为您答疑解惑</p>
        <el-image :src="options.customer_service"></el-image>
        <a>扫码立即联系</a>
        <p style="font-size: 12px">联系电话: 86-769-85613339</p>
      </div>

      <div class="customer-service"  slot="reference">
        <i class="el-icon-service"></i>
        <p>{{$t('page.console.menu.contact_us')}}</p>
      </div>
    </el-popover>

  </el-main>
</template>

<script>
// swiper
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import {getOption} from '@/api/os/admin';

export default {
  name: 'Home',
  components: {
    swiper,
    swiperSlide
  },
  data () {
    return {
      loading: true,
      swiperOption: {
          loop: true,
          autoplay: {
            disableOnInteraction: true // 手动切换之后继续自动轮播
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true // 允许点击小圆点跳转
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
      },
      banners: [
        require('@/assets/images/banner/1.png'), 
        require('@/assets/images/banner/2.png'), 
        require('@/assets/images/banner/3.png'),
        require('@/assets/images/banner/4.png'), 
        require('@/assets/images/banner/5.png'),
      ],
      // 常见问题
      activeCollapse: '1',
      // 推荐
      project: 'reco',

      options: {
        banners: {
          list: []
        }
      },
      active: 0
    }
  },
  created() {
    this.get()
  },
  computed: {
    // add
    lang() {
      if(this.$i18n.locale == 'zh-CN') {
        return ''
      }
      return 'en_';
    }
  },
  methods: {
    // async getList() {
    //   const {data: res} = await this.$http.get('/Activity/index')
    //   console.log(res)
    // },
    // 推荐
    projectClick(tab, event) {
      console.log(tab, event);
    },
    get() {
      this.loading = true
      getOption({
        name: 'page-home',
      }).then(res => {
        if(res.data.data) {
          this.options = res.data.data
        }
        this.loading = false
      })
    },
    goto(url) {
      if(url) {
        window.location = url
      }
    },
    changeImage(index) {
      this.active = index
    },
    showDynamics(name) {
      let ref = this.$refs.carousel[0];
      ref.setActiveItem(name)
    }
  }
}
</script>

<style lang="less" scoped>
.custom-appear-active{
  /*color: red;*/
  background: red;
  transition: all 2s ease;
}
.custom-appear{
  font-size: 40px;
  /*color: #e069e2;*/
  background: blue;
}
.custom-appear-to{
  /*color: #e29138;*/
  background: burlywood;
}
.dynamics-text{
  height: 100%;
  margin-top: 10px;
  display:flex;
  flex-direction: column;
  li{
    //height: 33.33%;
    flex:1;
    font-size: 1.2rem;
    cursor: pointer;
    h3{
      &:hover{
        color: #00923F;
      }
    }
  }
}
.banner_shadow--20 {
  background: rgba(0,0,0,0.1);
}
.banner_shadow {
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
}
.banner_text {
  position: absolute;
  top: 0px;
  padding-bottom: 120px;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.1);
  color: #FFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .ten_main {
    //width: 100%;
    max-width: 1200px;
    margin: auto;
    background: rgba(0, 0, 0, 0.3);
    padding: 0 10px;
    border-radius: 5px;
    h2 {
      max-width: 950px;
      font-size: 48px;
      line-height: 1.4em;
      margin: 12px 0px;
    }
    h5 {
      font-size: 18px;
      line-height: 1.8em;
      margin: 12px 0px;
      padding: 0px;
      font-weight: normal;
    }
  }
}
.wrap_content_item_box {
  border: 1px solid #e6ebf5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .05);
  cursor: pointer;
  .wrap_content_item_box_card {
    &:hover{
      .title.desc{
        //color: #00923F;
        color: #ffffff;
      }
    }
    .title {
      height: 52px;
      margin: 10px;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .desc {
      font-size: .8rem;
      height: 52px;
      margin: 10px;
      //color: #A2A2A2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
}
.active{
  color: #00923F;
}
.wxCode{
  text-align: center;
  p{
    color: #787878;
    margin: 10px auto;
  }
}
.customer-service{
  position: fixed;
  right: calc( (100% - 1200px) / 2 - 100px);
  bottom: 16%;
  z-index: 99;
  background: #ffffff;
  color: #00923F;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 2px 12px 0 rgba(20, 221, 107, 0.5);
  .el-icon-service{
    font-size: 50px;
  }
}
.dynamics-carousel{
  height: 320px;
  .el-carousel__container{
    height: 100%;
  }
}


@media screen and (max-width: 768px) {
  .banner_text .ten_main h2 {
    font-size: 30px;
    line-height: 1.4em;
  }
  .wrap_content_item_box{
    .el-image{
      height: 130px !important;
    }
    .wrap_content_item_box_card{
      .title{
        height: 36px;
        font-size: 14px;
        line-height: 18px;
      }
      .desc{
        height: 36px;
        font-size: 12px;
      }
    }
  }
  .dynamics-carousel{
    height: auto;
  }
}

@media screen and (max-width: 1248px) {
  .ten_main {
    margin: 0px 24px !important;
    width: auto;
  }
}
</style>
